import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import allApi from "../../../../api/allApi";

function WifiSetting() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("wifi");

  // Inside your component function:
  const [ssidDetails, setSsidDetails] = useState({
    ssid: '',
    broadcastStatus: false,
    band: [],
    vlanId: '',
    bandSteeringSt: false,
    fastRoamingSt: false,
    clientIsolation: false,
    AllowLocalUIAccess: false,
    securityAuthentication: 'WPA2 Personal',
    scheduleEnabled: false,
  });
  
  
  useEffect(() => {
    if (location.state && location.state.ssidDetails) {
      const ssidData = location.state.ssidDetails;
      const updatedSSIDDetails = {
        ssid: ssidData.ssid || '',
        broadcastStatus: ssidData.broadcastStatus === 1,
        band: ssidData.band ? ssidData.band.split(", ").map((band) => band.trim()) : [],
        vlanId: ssidData.vlanId || '',
        bandSteeringSt: ssidData.bandSteeringSt === "1",
        fastRoamingSt: ssidData.fastRoamingSt === "1",
        clientIsolation: ssidData.clientIsolation === "1",
        AllowLocalUIAccess: ssidData.allowLocalUIAccess === "1",
        securityAuthentication: ssidData.securityAuthentication || 'WPA2 Personal',
        scheduleEnabled: ssidData.scheduleEnabled === 1,
      };
      setSsidDetails(updatedSSIDDetails);
    }
  }, [location.state]);
  
  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setSsidDetails((prevDetails) => ({
      ...prevDetails,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  
  const handleBandChange = (e) => {
    const { value, checked } = e.target;
    setSsidDetails((prevDetails) => {
      const band = [...prevDetails.band];
      if (checked) {
        band.push(value);
      } else {
        const index = band.indexOf(value);
        if (index > -1) {
          band.splice(index, 1);
        }
      }
      return { ...prevDetails, band };
    });
  };
  

  const handleSave = async () => {
    try {
      // Ensure band is an array
      if (!Array.isArray(ssidDetails.band)) {
        throw new Error("band is not an array");
      }
  
      // Prepare the payload for the API request
      const payload = {
        wnId: ssidDetails.wnId,
        ssid: ssidDetails.ssid,
        networkId: ssidDetails.networkId,
        broadcastStatus: ssidDetails.broadcastStatus ? 1 : 0,
        band: ssidDetails.band.join(", "),
        vlanId: ssidDetails.vlanId,
        bandSteeringSt: ssidDetails.bandSteeringSt ? 1 : 0,
        fastRoamingSt: ssidDetails.fastRoamingSt ? 1 : 0,
        clientIsolation: ssidDetails.clientIsolation ? 1 : 0,
        AllowLocalUIAccess: ssidDetails.AllowLocalUIAccess ? 1 : 0,
        securityAuthentication: ssidDetails.securityAuthentication,
      };
      const api = new allApi();
      const response = await api.putRequest(
        `api/wireless/configuration/editSsid?networkId=${ssidDetails.networkId}`,
        payload
      );
      if (!response || response.error) {
        throw new Error(response.error || "Failed to update SSID");
      }
      alert('SSID updated successfully');
      navigate('/wifi-config');
    } catch (error) {
      console.error("Error:", error);
      alert('Error updating SSID: ' + error.message);
    }
  };
  

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const tabTitles = {
    wifisetting: "WiFi Settings",
    macacl: "MAC ACL",
    trafficPolicies: "Traffic Policies",
  };

  const currentTitle = tabTitles[activeTab] || "WiFi Settings";

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>{currentTitle}</h3>
        </div>
        <div className="inc-card-body">
          <div className="sideTab-container">
            <div className="inc-side-tab">
              <ul className="nav-side" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "wifi" ? "active" : ""}`}
                    onClick={() => handleTabChange("wifi")}
                    role="tab"
                    aria-controls="pills-wifi"
                    aria-selected={activeTab === "wifi"}
                  >
                    Settings <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "macacl" ? "active" : ""}`}
                    onClick={() => handleTabChange("macacl")}
                    role="tab"
                    aria-controls="pills-macacl"
                    aria-selected={activeTab === "macacl"}
                  >
                    MAC ACL <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "trafficPolicies" ? "active" : ""}`}
                    onClick={() => handleTabChange("trafficPolicies")}
                    role="tab"
                    aria-controls="pills-traffic"
                    aria-selected={activeTab === "trafficPolicies"}
                  >
                    Traffic Policies <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div className="inc-side-tabcontainer">
              <div className="tab-content" id="pills-tabContent">
                {activeTab === "wifi" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-wifi"
                    role="tabpanel"
                    aria-labelledby="pills-wifi-tab"
                  >
                    <div className="add-ssid-body editssid-scroll">
                      <h4>Wireless Settings</h4>
                      <div className="row d-flex">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="SSID"
                            name="ssid"
                            value={ssidDetails.ssid}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <div className="switch-box">
                            <strong>Broadcast SSID</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="broadcastStatus"
                                checked={ssidDetails.broadcastStatus}
                                onChange={handleInputChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <h4 className="mt-3">Band</h4>
                      <p>
                        Some legacy clients may not support WPA3 security.
                        Please select WPA3 Personal <br />
                        Mixed (WPA2+WPA3) mode.
                      </p>
                      <div className="row">
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="band"
                              value="All"
                              checked={ssidDetails.band.includes("All")}
                              onChange={handleBandChange}
                            />
                            <span className="indicator"></span>
                            <strong>All</strong>
                          </label>
                        </div>
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                          <input
  type="checkbox"
  name="band"
  value="2.4 GHz"
  checked={ssidDetails.band.includes("2.4 GHz")}
  onChange={handleBandChange}
/>

                            <span className="indicator"></span>
                            <strong>2.4 GHz</strong>
                          </label>
                        </div>
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="band"
                              value="5 GHz"
                              checked={ssidDetails.band.includes("5 GHz")}
                              onChange={handleBandChange}
                            />
                            <span className="indicator"></span>
                            <strong>5 GHz</strong>
                          </label>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <h4 className="mt-3">Security Settings</h4>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="securityAuthentication" className="col-form-label">
                            Security<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            name="securityAuthentication"
                            value={ssidDetails.securityAuthentication}
                            onChange={handleInputChange}
                          >
                            <option value="WPA2 Personal">WPA2 Personal</option>
                            <option value="WPA2 Personal Mixed">WPA2 Personal Mixed</option>
                            <option value="WPA3 Personal">WPA3 Personal</option>
                            <option value="WPA3 Personal Mixed (WPA2 + WPA3)">WPA3 Personal Mixed (WPA2 + WPA3)</option>
                          </select>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <h4 className="mt-3">Network Settings</h4>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="vlanId" className="col-form-label">
                            VLAN ID<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            name="vlanId"
                            value={ssidDetails.vlanId}
                            onChange={handleInputChange}
                          >
                            <option value="1">VLAN 1</option>
                            <option value="2">VLAN 2</option>
                          </select>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong>Client Isolation</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="clientIsolation"
                                checked={ssidDetails.clientIsolation}
                                onChange={handleInputChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong>Access To Local UI</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="AllowLocalUIAccess"
                                checked={ssidDetails.AllowLocalUIAccess}
                                onChange={handleInputChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <h4 className="mt-3 mb-4">Radio Settings</h4>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>Band Steering</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="bandSteeringSt"
                                checked={ssidDetails.bandSteeringSt}
                                onChange={handleInputChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>Fast Roaming (802.11r)</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="fastRoamingSt"
                                checked={ssidDetails.fastRoamingSt}
                                onChange={handleInputChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>802.11k/v</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="bandSteeringSt"
                                checked={ssidDetails.bandSteeringSt}
                                onChange={handleInputChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong>Enable SSID Schedule</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="ssidSchedule"
                                checked={ssidDetails.ssidSchedule || false}
                                onChange={handleInputChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="wifi-footer">
                      <button
                        type="button"
                        className="btn btn-outline"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="text-btn primary-btn"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
                {activeTab === "macacl" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-macacl"
                    role="tabpanel"
                    aria-labelledby="pills-macacl-tab"
                  >
                    {/* =============MAC ACL================ */}

                    <div className="row wifi-settings">
                      <div className="col-lg-6">
                        <div className="switch-box">
                          <strong>Enable MAC Access Control</strong>
                          <label className="ice-switch">
                            <input type="checkbox" />
                            <span className="slider"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mac-button">
                          <button type="button" className="btn btn-outline">
                            Manual
                          </button>
                          <button type="submit" className="btn primary-btn">
                            Remove Device
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 mt-4">
                      <div className="col-lg-6">
                        <label htmlFor="type" className="col-form-label">
                          Type<span className="text-danger">*</span>
                        </label>
                        <select className="form-select">
                          <option>Local ACL</option>
                          <option>Radius ACL</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <label htmlFor="policy" className="col-form-label">
                          Policy<span className="text-danger">*</span>
                        </label>
                        <select className="form-select">
                          <option>ALLOW</option>
                          <option>Deny</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "trafficPolicies" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-traffic"
                    role="tabpanel"
                    aria-labelledby="pills-traffic-tab"
                  >
                    {/* =============TRAFFIC================ */}

                    <div className="row wifi-settings">
                      <div className="col-lg-6">
                        <div className="switch-box">
                          <strong>DHCP Offer as Unicast</strong>
                          <label className="ice-switch">
                            <input type="checkbox" />
                            <span className="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WifiSetting;
