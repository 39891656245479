// Header.js
import React, { useState, useEffect ,useRef} from "react";
import { useNavigate } from "react-router-dom";
import AllApi from "../../../api/allApi";
import dropArrow from "../../../assets/images/dropArrow.png";
import allApi from "../../../api/allApi";
import { useTheme } from "../../../app/component/ThemeContext";

export default function Header({ heading,showBreadcrumb }) {
  const navigate = useNavigate();
  const [nameInitials, setNameInitials] = useState("");
  const [userName, setUserName] = useState("");
  const [showBadge, setShowBadge] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const notificationRef = useRef(null);

  useEffect(() => {
    if (showNotifications) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotifications]);

  useEffect(() => {
    const theme_value = sessionStorage.getItem("theme_key");
    if (theme_value == "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
      document.getElementById("theme").checked = true;
    } else {
      document.documentElement.setAttribute("data-theme", "light");
    }
    const userName = sessionStorage.getItem("username");
    setUserName(userName);
    themeChange();

    if (userName) {
      const [firstName, lastName] = userName.split(" ");
      const initials = `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
      setNameInitials(initials);
    }
    fetchNotifications();
  }, []);

  const logout = () => {
    const allApi = new AllApi();
    allApi
      .logout("api/auth/logout")
      .then((response) => {
        sessionStorage.removeItem("sessionToken");
      })
      .catch((error) => {
        console.error("Logout Error:", error);
      });
    navigate("/");
  };

  const themeChange = () => {
    const toggleSwitch = document.getElementById("theme");
    function switchTheme(e) {
      if (e.target.checked) {
        document.documentElement.setAttribute("data-theme", "dark");
        sessionStorage.setItem("theme_key", "dark");
      } else {
        document.documentElement.setAttribute("data-theme", "light");
        sessionStorage.setItem("theme_key", "light");
      }
    }
    toggleSwitch.addEventListener("change", switchTheme, false);
  };

  const fetchNotifications = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        "api/notification/allNotifications"
      );
      const notifications = response.map((notification) => ({
        id: notification.id || notification.notificationId,
        description: notification.description,
        createdAt: notification.createdAt,
        deviceName: notification.deviceName || "N/A",
      }));
      setNotifications(notifications.slice(0, 5));
      setNotificationCount(notifications.length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const markAsRead = async (notificationIds) => {
    try {
      const api = new allApi();
      const payload = notificationIds;
      await api.postRequest("api/notification/markNotifications", "", payload);
      setNotifications(
        notifications.filter((n) => !notificationIds.includes(n.id))
      );
      setNotificationCount((prevCount) => prevCount - notificationIds.length);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const handleSingleRead = (notificationId) => {
    markAsRead([notificationId]);
  };

  const handleMarkAllAsRead = () => {
    const allNotificationIds = notifications.map((n) => n.id);
    markAsRead(allNotificationIds);
  };

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setShowNotifications(false); // Close the notification panel
    }
  };

  const handleViewAllClick = () => {
    navigate("/notification");
  };

  const handleClick = () => {
    setShowBadge(true);
    navigate("/notification");
  };
  return (
    <header id="top-bar">
      <div className="header-title">
      {heading}
        {showBreadcrumb && (
          <p>
            <a href="#">Heading</a> <i className="fa fa-angle-right" aria-hidden="true"></i> {heading}
          </p>
        )}
      </div>
      <div className="header-control">
        <div className="switch-box">
          <label className="ice-switch">
            <input
              type="checkbox"
              className="theme"
              id="theme"
              checked={theme === "dark"}
              onChange={toggleTheme}
            />
            <span className="slider"></span>
          </label>
          <span>Dark Mode</span>
        </div>
        <div className="header-notification" onClick={handleNotificationClick}>
          {/* <span className="notifications-count">
            {notificationCount > 0 && <span>{notificationCount}</span>}
            <i className="icon notifications"></i>
          </span> */}
          <i className="icon notifications notifications-count">
            {notificationCount > 0 && <span>{notificationCount}</span>}
          </i>
          {showNotifications && (
            <div  ref={notificationRef} className="top-notification">
              <div className="notification-title">
                Notifications
                <span className="mark-all" onClick={handleMarkAllAsRead}>
                  Mark All as Read
                </span>{" "}
              </div>
              <div className="notification-box">
                {notifications.map((notification, index) => (
                  <div
                    className="notification-item"
                    key={index}
                    onClick={() => handleSingleRead(notification.id)}
                  >
                    <div>
                      <strong>{notification.deviceName || "N/A"}</strong>{" "}
                      <span>{notification.description}</span>{" "}
                    </div>
                    <div>
                      <span>
                        {new Date(notification.createdAt).toLocaleString()}
                      </span>{" "}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="view-all-notification"
                onClick={handleViewAllClick}
              >
                View All
              </div>
            </div>
          )}
        </div>

        <div className="name-post">
          <strong>{userName}</strong>
        </div>
        <div className="user-access">
          <div data-bs-toggle="dropdown" aria-expanded="false">
            <div className="header-profile-user">
              <span className="profile-user dark">{nameInitials}</span>
              <span className="drop">
                <img src={dropArrow} width="12" alt="" />
              </span>
            </div>
          </div>
          <div className="dropdown-menu dropdown-menu-right">
            <button className="dropdown-item">Profile Settings</button>
            <button className="dropdown-item" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
