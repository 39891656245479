import React, { useState, useEffect } from "react";
import deviceone from "../../../assets/images/r1.png";
import devicetwo from "../../../assets/images/r2.png";
import devicethree from "../../../assets/images/r3.png";
import devicefour from "../../../assets/images/r4.png";
import allApi from "../../../api/allApi";
import errorIcon from "../../../assets/images/error-2.png";
import { all } from "axios";

function HTTPS() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [models, setModels] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceMonitoringInterval, setDeviceMonitoringInterval] = useState("");
  const [deviceHealthInterval, setDeviceHealthInterval] = useState("");
  const [image_path, setImage] = useState("");
  const [editData, setEditData] = useState(null);


  const [formData, setFormData] = useState({
    modelName: "",
    deviceType: "",
    deviceMonitoringInterval: "",
    deviceHealthInterval: "",
    currentFW: "",
    // capabilities: "",
  });
  const [fileErrors, setFileErrors] = useState({
    deviceImage: "",
    vendorImage: "",
  });
  const [modelName, setModelName] = useState("");

  const handleAddModelClick = () => {
    setIsModalOpen(true);
  };


  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    if (editData && editModel) {
      setVendorName(editData.vendorName || "");
      setModelName(editData.modelName || "");
      setDeviceType(editData.deviceType || "");
      setDeviceMonitoringInterval(editData.deviceMonitoringInterval || "");
      setDeviceHealthInterval(editData.deviceHealthInterval || "");
      setImage(editData.image_path || "");
      console.log("Data in editData: ", editData); // Debug log to check edit data
    }
  }, [editData, editModel]);




  const fetchModels = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/model/allModels");
      setModels(response);
    } catch (error) { }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateImage = (file) => {
    const validTypes = ["image/png", "image/jpeg", "image/svg+xml"];
    const maxSize = 1 * 1024 * 1024;

    if (!validTypes.includes(file.type)) {
      return "Choose Correct File Path";
    }

    if (file.size > maxSize) {
      return "The file should be less than 1 MB";
    }

    return "";
  };

  const getImageDimensions = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };

  const handleFileChange = async (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const error = validateImage(file);
      if (error) {
        setFileErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
        return;
      }

      const { width, height } = await getImageDimensions(file);
      if ((width !== 16 || height !== 16) && (width > 32 || height > 32)) {
        setFileErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "Image dimensions should be either 16x16 or up to 32x32.",
        }));
        return;
      }

      setFileErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      setFormData((prevData) => ({ ...prevData, [field]: file }));
    }
  };

  const getModelOptions = () => {
    switch (formData.deviceType) {
      case "AP":
        return [
          { value: "WAX650", label: "WAX650" },
          { value: "WAX650E", label: "WAX650E" },
        ];
      case "SW":
        return [
          { value: "GXCP", label: "GXCP" },
          { value: "GC320E", label: "GC320E" },
        ];
      case "ROUTER":
        return [
          { value: "BR500", label: "BR500" },
          { value: "PR60", label: "PR60" },
        ];
      default:
        return [];
    }
  };

  const handleAddModal = async () => {
    try {
      const payload = {
        vendor_name: formData.vendorName,
        modelName: formData.modelName,
        deviceType: formData.deviceType,
        deviceMonitoringInterval: parseInt(formData.deviceMonitoringInterval),
        deviceHealthInterval: parseInt(formData.deviceHealthInterval),
        image: formData.deviceImage
          ? URL.createObjectURL(formData.deviceImage)
          : "",
      };

      const api = new allApi();
      await api.postRequest("api/model/addHttpModel", "", payload);

      setModels((prevModels) => [...prevModels, payload]);
      setIsModalOpen(false);

      setFormData({
        vendorName: "",
        modelName: "",
        deviceType: "",
        deviceMonitoringInterval: "",
        deviceHealthInterval: "",
        image: "",
      });
    } catch (error) { }
  };

  const handleDeleteModel = async (modelName, deviceType) => {
    try {
      const api = new allApi();
      const payload = { modelName, deviceType };
      await api.deleteRequest("api/model/deleteHttpModel", payload);

      // Update the models list after deletion
      setModels((prevModels) =>
        prevModels.filter((model) => model.modelName !== modelName)
      );

      alert(`${modelName} is deleted successfully.`);
    } catch (error) {
      console.error("Error deleting model", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEditData = async (deviceType, modelName) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/model/getHttpModel?deviceType=${deviceType}&modelName=${modelName}`);
      console.log("API Response: ", response);  // Log API response
      if (response) {
        setEditData(response);
        setEditModel(true);
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };



  // const handleEditModelClick = () => {
  //   handleEditData();
  //   setEditModel(true);
  // };

  const handleClose = () => {
    setEditModel(false);
  }

  // const updateModel =()=>{
  //   try{
  //    const api = new allApi();
  //    const payload = {
  //     modelName,
  //     deviceType,
  //     deviceMonitoringInterval,
  //     deviceHealthInterval,
  //     image_path,
  //    }
  //    const response = api.putRequest(`api/model/updateHttpModel`,payload);
  //    if(response){
  //     handleClose();
  //    }
  //   }catch(error){
  //    console.error("Failed to update Model",error);
  //   }
  // }


  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>HTTPS Management</h3>
            <div className="inc-card-button">
              <button
                className="text-btn primary-btn"
                onClick={handleAddModelClick}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                Add
              </button>

              <button class="icon-btn" title="Refresh">
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
              {/* <button class="icon-btn" title="Delete">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button> */}
            </div>
          </div>
          <div className="inc-card-body">
            <div className="inc-card-table">
              <table className="full-table m-head device-pic">
                <thead>
                  <tr>
                    <th>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </th>
                    <th>Model Image</th>
                    <th>Model Name</th>
                    <th>Device Type</th>
                    <th>Communication protocol</th>
                    <th>Monitoring interval</th>
                    <th>Health interval</th>
                    <th>Capabilities</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {models.map((model) => (
                    <tr key={model.id}>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>
                        <img src={deviceone} alt="Device" />
                      </td>
                      <td>{model.modelName || "N/A"}</td>
                      <td>{model.deviceType || "N/A"}</td>
                      <td>{model.deviceCommunicationProtocol || "N/A"}</td>
                      <td>{model.deviceMonitoringInterval || "N/A"}</td>
                      <td>{model.deviceHealthInterval || "N/A"}</td>
                      <td>{model.capabilities || "N/A"}</td>
                      <td>
                        <div className="button-group">
                          <button
                            title="Delete"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModel"
                            onClick={() =>
                              handleDeleteModel(
                                model.modelName,
                                model.deviceType
                              )
                            }
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                          <button title="Edit">
                            <i className="fa fa-pencil" aria-hidden="true" onClick={() => {
                              handleEditData(
                                model.deviceType,
                                model.modelName
                              )
                            }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {isModalOpen && (
            <div className="customModal">
              <div className="c-modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Model</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCancel}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-ssid-body">
                      <form>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="vendorName"
                              className="col-form-label"
                            >
                              Vendor Name
                            </label>
                            <select
                              className="form-select"
                              name="vendorName"
                              value={formData.vendorName}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Vendor</option>
                              <option value="NETGEAR">NETGEAR</option>
                              <option value="CISCO">CISCO</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceType"
                              className="col-form-label"
                            >
                              Device Type
                            </label>
                            <select
                              className="form-select"
                              name="deviceType"
                              value={formData.deviceType}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Device Type</option>
                              <option value="AP">AP</option>
                              <option value="SW">Switch</option>
                              <option value="ROUTER">Router</option>
                            </select>
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="modelName"
                              className="col-form-label"
                            >
                              Model Name
                            </label>
                            <select
                              className="form-select"
                              name="modelName"
                              value={formData.modelName}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Model</option>
                              {getModelOptions().map((model) => (
                                <option key={model.value} value={model.value}>
                                  {model.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceMonitoringInterval"
                              className="col-form-label"
                            >
                              Device Monitoring Interval
                            </label>
                            <select
                              className="form-select"
                              name="deviceMonitoringInterval"
                              value={formData.deviceMonitoringInterval}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Interval</option>
                              <option value="1">1 Min</option>
                              <option value="5">5 Min</option>
                              <option value="10">10 Min</option>
                              <option value="15">15 Min</option>
                            </select>
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceHealthInterval"
                              className="col-form-label"
                            >
                              Device Health Interval
                            </label>
                            <select
                              className="form-select"
                              name="deviceHealthInterval"
                              value={formData.deviceHealthInterval}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Interval</option>
                              <option value="1">1 Min</option>
                              <option value="5">5 Min</option>
                              <option value="10">10 Min</option>
                              <option value="15">15 Min</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="DeviceImage"
                              className="col-form-label"
                            >
                              Device Image
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="deviceImage"
                              onChange={(e) =>
                                handleFileChange(e, "deviceImage")
                              }
                            />
                            {fileErrors.deviceImage && (
                              <div className="text-danger">
                                {fileErrors.deviceImage}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border-line"></div>

                        <h4 className="mt-2">Capabilities</h4>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label htmlFor="port" className="col-form-label">
                              Supported Port
                            </label>
                            <select
                              className="form-select"
                              name="port"
                              placeholder="port"
                            >
                              <option>8</option>
                              <option>12</option>
                              <option>16</option>
                              <option>32</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="ClientSupport"
                              className="col-form-label"
                            >
                              MAX Client Support
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="ClientSupport"
                              placeholder="ClientSupport"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label>&nbsp;</label>
                            <div className="switch-box">
                              <strong>Radio Enabled</strong>
                              <label className="ice-switch">
                                <input type="checkbox" name="Radio" />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="text-btn primary-btn"
                      onClick={handleAddModal}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {editModel && (
            <div className="customModal">
              <div className="c-modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Model</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleClose}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-ssid-body">
                      <form>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label htmlFor="vendorName" className="col-form-label">
                              Vendor Name
                            </label>
                            <select
                              className="form-select"
                              name="vendorName"
                              value={editData?.vendorName} 
                              // onChange={handleInputChange}
                              readOnly
                            >
                              <option value="">Select Vendor</option>
                              <option value="NETGEAR">NETGEAR</option>
                              <option value="CISCO">CISCO</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="deviceType" className="col-form-label">
                              Device Type
                            </label>
                            <select
                              className="form-select"
                              name="deviceType"
                              value={deviceType} // Bind to state
                              onChange={handleInputChange}
                              readOnly
                            >
                              <option value="">Select Device Type</option>
                              <option value="AP">AP</option>
                              <option value="SW">Switch</option>
                              <option value="ROUTER">Router</option>
                            </select>
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label htmlFor="modelName" className="col-form-label">
                              Model Name
                            </label>
                            <select
                              className="form-select"
                              name="modelName"
                              value={modelName} // Bind to state
                              onChange={handleInputChange}
                              readOnly
                            >
                              <option value="">Select Model</option>
                              {getModelOptions().map((model) => (
                                <option key={model.value} value={model.value}>
                                  {model.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceMonitoringInterval"
                              className="col-form-label"
                            >
                              Device Monitoring Interval
                            </label>
                            <select
                              className="form-select"
                              name="deviceMonitoringInterval"
                              value={deviceMonitoringInterval} // Bind to state
                              onChange={handleInputChange}
                            >
                              <option value="">Select Interval</option>
                              <option value="1">1 Min</option>
                              <option value="5">5 Min</option>
                              <option value="10">10 Min</option>
                              <option value="15">15 Min</option>
                            </select>
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="deviceHealthInterval"
                              className="col-form-label"
                            >
                              Device Health Interval
                            </label>
                            <select
                              className="form-select"
                              name="deviceHealthInterval"
                              value={deviceHealthInterval} // Bind to state
                              onChange={handleInputChange}
                            >
                              <option value="">Select Interval</option>
                              <option value="1">1 Min</option>
                              <option value="5">5 Min</option>
                              <option value="10">10 Min</option>
                              <option value="15">15 Min</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="DeviceImage" className="col-form-label">
                              Device Image
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="deviceImage"
                              onChange={(e) => handleFileChange(e, "deviceImage")}
                            />
                            {fileErrors.deviceImage && (
                              <div className="text-danger">
                                {fileErrors.deviceImage}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="text-btn primary-btn"
                      // onClick={updateModel}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}




        </div>
      </div>
      <div
        className={`modal fade`}
        id="deleteModel"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure to delete {modelName} Model?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => handleDeleteModel(modelName)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HTTPS;
