import React from "react";
import deviceone from "../../../assets/images/r1.png";
import devicetwo from "../../../assets/images/r2.png";
import devicethree from "../../../assets/images/r3.png";
import devicefour from "../../../assets/images/r4.png";
import { useEffect, useState } from "react";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function Troubleshoot({ devices }) {
  const [deviceListings, setDeviceListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [devicesResponse, setDevicesResponse] = useState([]);
  const [totalDevices, setTotalDevices] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualCheckboxChecked, setIndividualCheckboxChecked] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedTestOption, setSelectedTestOption] = useState("");
  const [showAdvancedInfo, setShowAdvancedInfo] = useState(false);
  const navigate = useNavigate();
  const [resp, setResp] = useState(null);
  const [count, setCount] = useState("4");
  const [size, setSize] = useState("46");
  const [timeout, setTimeout] = useState("5");
  const [interval, setInterval] = useState("1");
  const [errorMessage, setErrorMessage] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [countError, setCountError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const [timeoutError, setTimeoutError] = useState("");
  const [intervalError, setIntervalError] = useState("");
  const [showPingTestPopup, setShowPingTestPopup] = useState(false);
  const [showTraceoutPopup, setShowTraceoutPopup] = useState(false);
  const [showDNSLookUpPopup, setShowDNSLookUpPopup] = useState(false);
  const [traceoutResponse, setTraceoutResponse] = useState(null);
  const [traceoutParams, setTraceoutParams] = useState({
    count: 4,
    maxTTL: 30,
    initTTL: 1,
    interval: 1,
    port: 33434,
    size: 46,
    host: "",
  });
  const [traceoutErrors, setTraceoutErrors] = useState({
    countError: "",
    maxTTLError: "",
    initTTLError: "",
    intervalError: "",
    portError: "",
    sizeError: "",
  });
  const [dnsDomain, setDnsDomain] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [selectedSerialNumber, setSelectedSerialNumber] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const deviceId = searchParams.get("deviceId");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showFailurePopup, setShowFailurePopup] = useState(false);
  const [showTracerouteSuccessPopup, setShowTracerouteSuccessPopup] = useState(false);
const [showDNSSuccessPopup, setShowDNSSuccessPopup] = useState(false);

  

  useEffect(() => {
    if (location.state && location.state.deviceTroubleshoot) {
      console.log(
        "Device Troubleshoot Data:",
        location.state.deviceTroubleshoot
      );
      setDevicesResponse([location.state.deviceTroubleshoot]);
      setLoading(false);
      setRefreshing(false);
    } else {
      getDeviceDetails();
    }
  }, [location.state]);

  useEffect(() => {}, [devicesResponse]);

  async function getDeviceDetails() {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const devicesResponse = await api.getRequest("api/devices");
      if (Array.isArray(devicesResponse)) {
        setDevicesResponse(devicesResponse);
        setTotalDevices(devicesResponse.length);
      } else {
        console.error("Unexpected response format:", devicesResponse);
        setDevicesResponse([]);
        setTotalDevices();
      }
    } catch (error) {
      console.error("Error fetching device data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }

  const handlePingTest = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setShowPingTestPopup(false);
    try {
      const api = new allApi();
      const selectedDevices = devicesResponse.filter(
        (device) => individualCheckboxChecked[device.macAddress]
      );
      const selectedDevice = selectedDevices;
      console.log("selected device",selectedDevice);
      const payload = {
        SerialNumber: serialNumber,
        type: 67,
        ipVersion: 4,
        host: ipAddress,
        action: 1,
        count,
        size,
        timeout,
        interval,
      };
      const initialResponse = await api.postRequest(
        "api/troubleshoot/ping",
        "",
        payload
      );
      const uuid = initialResponse.uuid;
      setDevicesResponse(
        devicesResponse.map((device) =>
          device.macAddress === selectedDevice.macAddress
            ? { ...device, pingTestStatus: "In Progress" }
            : device
        )
      );

      const pollStatus = async () => {
        try {
          const statusResponse = await api.getRequest(
            `api/troubleshoot/response/${uuid}`
          );
          const { status } = statusResponse;

          if (status === null || status === 1) {
            // Success
            setResp({ status: "SUCCESS", data: statusResponse });
            setDevicesResponse(
              devicesResponse.map((device) =>
                device.macAddress === selectedDevice.macAddress
                  ? { ...device, pingTestStatus: "Success" }
                  : device
              )
            );
            setShowPingTestPopup(false);
            setShowSuccessPopup(true);
            getDeviceDetails();
            
          } else if (status === 0) {
            // Continue polling
            setDevicesResponse(
              devicesResponse.map((device) =>
                device.macAddress === selectedDevice.macAddress
                  ? { ...device, pingTestStatus: "In Progress" }
                  : device
              )
            );

            setTimeout(pollStatus, 5000);
          } else if (status === 2) {
            // Failure
            setResp({ status: "FAILED", data: statusResponse });
            setDevicesResponse(
              devicesResponse.map((device) =>
                device.macAddress === selectedDevice.macAddress
                  ? { ...device, pingTestStatus: "Failed" }
                  : device
              )
            );
            setShowPingTestPopup(false);
            setShowFailurePopup(true);
          }
        } catch (error) {
          console.error("Error polling status:", error);
          setErrorMessage("Error polling status. Please try again.");
        }
      };

      // Start polling
      pollStatus();
    } catch (error) {}
  };

  const handleShowSuccessPopup = () => setShowSuccessPopup(true);
  const handleShowFailurePopup = () => setShowFailurePopup(true);
  const handleClosePopups = () => {
    setShowSuccessPopup(false);
    setShowFailurePopup(false);
    setShowDNSSuccessPopup(false);
    setShowTracerouteSuccessPopup(false);
  };

  const selectedDevices = devicesResponse.filter(
    (device) => individualCheckboxChecked[device.macAddress]
  );
  const selectedDevice = selectedDevices; 

  const handleTracerouteTest = async (e) => {
    e.preventDefault();
    setErrorMessage("");  
    setShowTraceoutPopup(false);  
    const selectedDevices = devicesResponse.filter(
      (device) => individualCheckboxChecked[device.macAddress]
    );
    const selectedDevice = selectedDevices; 
  
    if (!selectedDevice) {
      setErrorMessage("No device selected.");
      return; 
    }
  
    try {
      const api = new allApi();
      const payload = {
        SerialNumber: serialNumber,
        wait: 3,
        ipVersion: 4,
        size: parseInt(traceoutParams.size, 10),
        port: parseInt(traceoutParams.port, 10),
        maxTTL: parseInt(traceoutParams.maxTTL, 10),
        host: traceoutParams.host,
        action: 1,
        initTTL: parseInt(traceoutParams.initTTL, 10),
        nQueries: parseInt(traceoutParams.count, 10),
        type: 68,
      };
  
      const initialResponse = await api.postRequest(
        "api/troubleshoot/traceroute",
        "",
        payload
      );
      const uuid = initialResponse.uuid;
  
      setDevicesResponse(
        devicesResponse.map((device) =>
          device.macAddress === selectedDevice.macAddress
            ? { ...device, tracerouteTestStatus: "In Progress" }
            : device
        )
      );
  
      const pollStatus = async () => {
        try {
          const statusResponse = await api.getRequest(
            `api/troubleshoot/response/${uuid}`
          );
          const { status } = statusResponse;
  
          if (status == null || status == 0) {
            // Success
            setResp({ status: "SUCCESS", data: statusResponse });
            setDevicesResponse(
              devicesResponse.map((device) =>
                device.macAddress === selectedDevice.macAddress
                  ? { ...device, tracerouteTestStatus: "Success" }
                  : device
              )
            );
            setShowTraceoutPopup(false);
            setShowTracerouteSuccessPopup(true);;
          } else if (status == 1) {
            setDevicesResponse(
              devicesResponse.map((device) =>
                device.macAddress === selectedDevice.macAddress
                  ? { ...device, tracerouteTestStatus: "In Progress" }
                  : device
              )
            );
  
            setTimeout(pollStatus, 2000);  // Poll every 2 seconds
          } else if (status == 2) {
            // Failure
            setResp({ status: "FAILED", data: statusResponse });
            setDevicesResponse(
              devicesResponse.map((device) =>
                device.macAddress === selectedDevice.macAddress
                  ? { ...device, tracerouteTestStatus: "Failed" }
                  : device
              )
            );
            setShowTraceoutPopup(false);
            setShowFailurePopup(true);
          }
        } catch (error) {
          setErrorMessage("Error polling status. Please try again.");
        }
      };
  
      // Start polling
      pollStatus();
    } catch (error) {
      setErrorMessage("Failed to initiate traceroute test. Please try again.");
    }
  };
  
  const handleSerialNumberClick = (serialNumber) => {
    setSelectedSerialNumber(serialNumber);
  };
  
 const handleDNSLookoutTest = async (e) => {
  e.preventDefault();

  try {
    const api = new allApi();
    const payload = {
      SerialNumber: serialNumber,
      domainName: dnsDomain,
    };

    const response = await api.postRequest("api/troubleshoot/dnsLookup", "", payload);

    if (response && response.uuid) {
      const uuid = response.uuid;

      

      // Start polling for DNS lookup status
      pollDNSStatus(uuid);

    } else {
      // No UUID received, handle failure
      handleShowFailurePopup();
    }
  } catch (error) {
    console.error("Error in handleDNSLookoutTest:", error);
    handleShowFailurePopup();
  }
};
// Function to poll the DNS lookup response
const pollDNSStatus = async (uuid) => {
  try {
    const api = new allApi();
    const statusResponse = await api.getRequest(`api/troubleshoot/response/${uuid}`);

    if (statusResponse) {
      const { status, response_data } = statusResponse;  // Ensure correct structure
      console.log("Polling response:", statusResponse);  // Debugging: log the response

      if (status == null || status == 0) {
        // Success case
        console.log("DNS lookup successful:", response_data);  // Debugging: log success
        setShowDNSLookUpPopup(false);  // Hide any other popup
        setShowDNSSuccessPopup(true);  // Show success modal
      } else if (status == 1) {
        console.log("DNS lookup still in progress, polling again...");  // Debugging: log progress
        setTimeout(() => {
          pollDNSStatus(uuid);
        }, 3000);  // Retry polling after 3 seconds
      } else if (status == 2) {
        // Failure case
        console.log("DNS lookup failed");  // Debugging: log failure
        handleShowFailurePopup();
      }
    } else {
      console.log("No response from API");  // Debugging: log null response
      handleShowFailurePopup();
    }
  } catch (error) {
    console.error("Error polling DNS lookup status:", error);
    handleShowFailurePopup();
  }
};

  const handleError = (error) => {
    if (error.response) {
      console.log("Error Response Data:", error.response.data);
      const { status, data } = error.response;
      if (status === 500) {
        if (data.message === "Device not found") {
          setErrorMessage("Device not found.");
        } else {
          setErrorMessage("An error occurred.");
        }
      } else {
        console.error("Error occurred", error);
        setErrorMessage(
          "Network error. Please check your connection and try again."
        );
      }
    } else {
      console.error("Error occurred", error);
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const handleSelectAllCheckbox = () => {
    const newCheckedState = {};
    devicesResponse.forEach((device) => {
      newCheckedState[device.macAddress] = !selectAllChecked;
    });
    setSelectAllChecked(!selectAllChecked);
    setIndividualCheckboxChecked(newCheckedState);
  };

  const handleIndividualCheckboxChange = (macAddress,serialNumber) => {
    setSerialNumber(serialNumber)
    setIndividualCheckboxChecked((prev) => {
      const newCheckedState = { ...prev, [macAddress]: !prev[macAddress] };
      const allChecked = devicesResponse.every(
        (device) => newCheckedState[device.macAddress]
      );
      setSelectAllChecked(allChecked);
      return newCheckedState;
    });
  };

  const handleCancelModal = () => {
    setShowModal(false);
    setSelectedTestOption("");
    handleCancel();
  };

  const handleShowPopUp = () => {
    if (selectedTestOption === "pingTest") {
      setShowPingTestPopup(true);
    } else if (selectedTestOption === "traceroute") {
      setShowTraceoutPopup(true);
    } else if (selectedTestOption === "dnsLookup") {
      setShowDNSLookUpPopup(true);
    }
    handleCancelModal();
    setErrorMessage("");
  };

  const handleCancelPopUp = () => {
    setShowPingTestPopup(false);
    setShowTraceoutPopup(false);
    setShowDNSLookUpPopup(false);
    setIpAddress("");
    setShowAdvancedInfo(false);
    handleCancel();
  };

  const handleRunTestClick = () => {
    const isAnyCheckboxChecked = Object.values(individualCheckboxChecked).some(
      (isChecked) => isChecked
    );
    if (isAnyCheckboxChecked) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };
  const handleTestOptionChange = (event) => {
    setSelectedTestOption(event.target.value);
  };

  const handleAdvancedSettings = (e) => {
    e.preventDefault();
    setShowAdvancedInfo((prevState) => !prevState);
  };
  const handleCancel = () => {
    getDeviceDetails();
    setIndividualCheckboxChecked(false);
  };

  const handleCountValue = (e) => {
    const value = e.target.value;
    const numberValue = Number(value);
    setCount(value);
    if (value === "" || (numberValue >= 1 && numberValue <= 1024)) {
      setCountError("");
    } else {
      setCountError("Value must be between 1 and 1024");
    }
  };

  const handleSizeValue = (e) => {
    const value = e.target.value;
    const numberValue = Number(value);
    setSize(value);
    if (value === "" || (numberValue >= 46 && numberValue <= 1024)) {
      setSizeError("");
    } else {
      setSizeError("Value must be between 46 and 1024");
    }
  };

  const handleTimeoutValue = (e) => {
    const value = e.target.value;
    const numberValue = Number(value);
    setTimeout(value);
    if (value === "" || (numberValue >= 1 && numberValue <= 300)) {
      setTimeoutError("");
    } else {
      setTimeoutError("Value must be between 1 and 300");
    }
  };

  const handleIntervalValue = (e) => {
    const value = e.target.value;
    const numberValue = Number(value);
    setInterval(value);
    if (value === "" || (numberValue >= 1 && numberValue <= 10)) {
      setIntervalError("");
    } else {
      setIntervalError("Value must be between 1 and 10");
    }
  };

  const handleTraceoutParamsChange = (e) => {
    const { id, value } = e.target;
    setTraceoutParams((prevParams) => ({
      ...prevParams,
      [id]: value,
    }));
    validateTraceoutParams();
  };

  const disabledRunTest = !(
    ipAddress &&
    size &&
    timeout &&
    interval &&
    !countError &&
    !sizeError &&
    !timeoutError &&
    !intervalError
  );

  const validateTraceoutParams = () => {
    const newErrors = {};
    if (Number(traceoutParams.count) < 1 || Number(traceoutParams.count) > 10) {
      newErrors.countError = "Count must be between 1 and 10.";
    }
    if (
      Number(traceoutParams.maxTTL) < 1 ||
      Number(traceoutParams.maxTTL) > 255
    ) {
      newErrors.maxTTLError = "Max TTL must be between 1 and 255.";
    }
    if (
      Number(traceoutParams.initTTL) < 1 ||
      Number(traceoutParams.initTTL) > 30
    ) {
      newErrors.initTTLError = "Init TTL must be between 1 and 30.";
    }
    if (
      Number(traceoutParams.interval) < 1 ||
      Number(traceoutParams.interval) > 60
    ) {
      newErrors.intervalError = "Interval must be between 1 and 60.";
    }
    if (
      Number(traceoutParams.port) < 1 ||
      Number(traceoutParams.port) > 65535
    ) {
      newErrors.portError = "Port must be between 1 and 65535.";
    }
    if (
      Number(traceoutParams.size) < 46 ||
      Number(traceoutParams.size) > 1024
    ) {
      newErrors.sizeError = "Size must be between 46 and 1024.";
    }
    setTraceoutErrors(newErrors);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Troubleshoot</h3>
            <div className="inc-card-button">
              <input className="list-search" type="text" />
              <button
                className="icon-btn"
                title="Refresh"
                onClick={() => getDeviceDetails()}
              >
                {refreshing ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                )}
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={selectAllChecked}
                            onChange={() => handleSelectAllCheckbox()}
                          />
                          <span className="indicator"></span>
                        </label>
                      </th>
                      <th colSpan={2}>Device Information</th>
                      <th>Ping Test</th>
                      <th>DNS Lookup</th>
                      <th>Traceroute</th>
                    </tr>
                  </thead>
                  <tbody>
                    {devicesResponse.length > 0 ? (
                      devicesResponse.map((device, index) => (
                        <tr key={index}>
                          <td>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={
                                  individualCheckboxChecked[
                                    device.macAddress
                                  ] || false
                                }
                                onChange={() =>
                                  handleIndividualCheckboxChange(
                                    device.macAddress,
                                    device.serialNumber
                                  )
                                }
                              />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td className="t-device">
                            <img src={deviceone} alt="Device" />
                          </td>
                          <td>
                            <div className="t-device-info">
                              <p>
                                <strong>{device.deviceName}</strong> |{" "}
                                <a
                                  href="#"
                                  onClick={() =>
                                    handleSerialNumberClick(device.serialNumber)
                                  }
                                >
                                  Device Configuration
                                </a>
                              </p>
                              <p>Serial Number: {device.serialNumber}</p>
                              <p>Model: {device.model || "N/A"}</p>
                              <p>MAC Address: {device.macAddress}</p>
                            </div>
                          </td>
                          <td>{device.status || "-"}</td>
                          <td>{device.status || "-"}</td>
                          <td>{device.status || "-"}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No devices available.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="inc-card-footer flex-row-reverse">
            <button
              type="button"
              className="text-btn primary-btn"
              aria-label="RunTest"
              onClick={handleRunTestClick}
              disabled={!individualCheckboxChecked}
            >
              Run Test
            </button>
            <button
              type="button"
              className="btn btn-outline"
              aria-label="Close"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Choose Troubleshooting Options</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCancelModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="troubleshoot-option">
                  <label>
                    <input
                      type="radio"
                      name="troubleshoot"
                      value="pingTest"
                      onChange={handleTestOptionChange}
                    />
                    <h4>Ping Test</h4>
                    <p>
                      Test how long it takes for packets to go round trip from
                      the device to a target IP address or host.
                    </p>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="troubleshoot"
                      value="dnsLookup"
                      onChange={handleTestOptionChange}
                    />
                    <h4>DNS Lookup</h4>
                    <p>DNS resolves a domain name to a public IP address.</p>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="troubleshoot"
                      value="traceroute"
                      onChange={handleTestOptionChange}
                    />
                    <h4>Traceroute</h4>
                    <p>
                      View the path, hops and wait times for packets traveling
                      round trip to a host.
                    </p>
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={handleCancelModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={() => handleShowPopUp()}
                  disabled={!selectedTestOption}
                >
                  Test Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPingTestPopup && (
        <div className="customModal">
          <div className="c-modal-dialog w600 modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ping Test</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCancelPopUp}
                ></button>
              </div>
              <div className="modal-body">
                <label className="col-form-label">IP Address or Hostname</label>
                <input
                  type="text"
                  className="form-control"
                  id="ip"
                  value={ipAddress}
                  onChange={(e) => setIpAddress(e.target.value)}
                />
                <p className="mt-3">
                  Performing ping test default settings |{" "}
                  <a href="#" onClick={handleAdvancedSettings}>
                    Advanced Settings
                  </a>
                </p>
                {showAdvancedInfo && (
                  <div className="row sublabel">
                    <div className="col-lg-6 mb-2">
                      <label className="col-form-label">
                        <strong>Ping Count</strong> <span>(1 to 1024)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="count"
                        value={count}
                        onChange={handleCountValue}
                      />
                      {countError && (
                        <span
                          className="required-message "
                          style={{ color: "red" }}
                        >
                          {countError}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label className="col-form-label">
                        <strong>Packet Size</strong>{" "}
                        <span>(46 to 1024 bytes)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="size"
                        value={size}
                        onChange={handleSizeValue}
                      />
                      {sizeError && (
                        <span
                          className="required-message "
                          style={{ color: "red" }}
                        >
                          {sizeError}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="col-form-label">
                        <strong>Ping Timeout</strong>{" "}
                        <span>(1 to 300 Seconds)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="timeout"
                        value={timeout}
                        onChange={handleTimeoutValue}
                      />
                      {timeoutError && (
                        <span
                          className="required-message "
                          style={{ color: "red" }}
                        >
                          {timeoutError}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="col-form-label">
                        <strong>Ping Interval</strong>{" "}
                        <span>(1 to 10 Seconds)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="interval"
                        value={interval}
                        onChange={handleIntervalValue}
                      />
                      {intervalError && (
                        <span
                          className="required-message "
                          style={{ color: "red" }}
                        >
                          {intervalError}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={handleCancelPopUp}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={handlePingTest}
                  disabled={disabledRunTest}
                >
                  Run Test
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*===============TRACEOUT==============*/}
      {showTraceoutPopup && (
        <div className="customModal">
          <div className="c-modal-dialog w600 modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Traceroute</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCancelPopUp}
                ></button>
              </div>
              <div className="modal-body">
                <label className="col-form-label">IP Address or Hostname</label>
                <input
                  type="text"
                  className="form-control"
                  id="host"
                  value={traceoutParams.host}
                  onChange={handleTraceoutParamsChange}
                />
                <p className="mt-3">
                  Perform traceroute with default settings |{" "}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAdvancedSettings(e);
                    }}
                  >
                    Advanced Settings
                  </a>
                </p>

                {showAdvancedInfo && (
                  <div className="row sublabel">
                    <div className="col-lg-6 mb-2">
                      <label className="col-form-label">
                        <strong>Count</strong> <span>(1 to 10)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="count"
                        value={traceoutParams.count}
                        onChange={handleTraceoutParamsChange}
                      />
                      {traceoutErrors.countError && (
                        <span
                          className="required-message"
                          style={{ color: "red" }}
                        >
                          {traceoutErrors.countError}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label className="col-form-label">
                        <strong>Max TTL</strong> <span>(1 to 255)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="maxTTL"
                        value={traceoutParams.maxTTL}
                        onChange={handleTraceoutParamsChange}
                      />
                      {traceoutErrors.maxTTLError && (
                        <span
                          className="required-message"
                          style={{ color: "red" }}
                        >
                          {traceoutErrors.maxTTLError}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label className="col-form-label">
                        <strong>Init TTL</strong> <span>(1 to 30)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="initTTL"
                        value={traceoutParams.initTTL}
                        onChange={handleTraceoutParamsChange}
                      />
                      {traceoutErrors.initTTLError && (
                        <span
                          className="required-message"
                          style={{ color: "red" }}
                        >
                          {traceoutErrors.initTTLError}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label className="col-form-label">
                        <strong>Interval</strong> <span>(1 to 60 Seconds)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="interval"
                        value={traceoutParams.interval}
                        onChange={handleTraceoutParamsChange}
                      />
                      {traceoutErrors.intervalError && (
                        <span
                          className="required-message"
                          style={{ color: "red" }}
                        >
                          {traceoutErrors.intervalError}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label className="col-form-label">
                        <strong>Port</strong> <span>(1 to 65535)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="port"
                        value={traceoutParams.port}
                        onChange={handleTraceoutParamsChange}
                      />
                      {traceoutErrors.portError && (
                        <span
                          className="required-message"
                          style={{ color: "red" }}
                        >
                          {traceoutErrors.portError}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label className="col-form-label">
                        <strong>Size</strong> <span>(46 to 1024 bytes)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="size"
                        value={traceoutParams.size}
                        onChange={handleTraceoutParamsChange}
                      />
                      {traceoutErrors.sizeError && (
                        <span
                          className="required-message"
                          style={{ color: "red" }}
                        >
                          {traceoutErrors.sizeError}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={handleCancelPopUp}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={handleTracerouteTest}
                >
                  Run Test
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*===============DNS Lookup==============*/}
      {showDNSLookUpPopup && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered small">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Domain Name</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCancelPopUp}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  {/* <label className="form-label">Domain Name</label> */}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="www.example.com"
                    onChange={(e) => setDnsDomain(e.target.value)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={handleCancelPopUp}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={handleDNSLookoutTest}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>
        Test performed successfully
        </p>
        <p>Test Result: 
        PING 8.8.8.8 (8.8.8.8): 64 data bytes<br />
        72 bytes from 8.8.8.8: seq=0 ttl=118 time=10.000 ms<br />
        72 bytes from 8.8.8.8: seq=1 ttl=118 time=0.000 ms<br />
        72 bytes from 8.8.8.8: seq=2 ttl=118 time=10.000 ms <br />
        8.8.8.8 ping statistics <br />
        3 packets transmitted, 3 packets received, 0% packet loss<br />
        round-trip min/avg/max = 0.000/6.666/10.000 ms<br />
        </p>
        <Button
          onClick={() => handleClosePopups()}
        >
          OK
        </Button>
      </Modal>

      <Modal
  show={showTracerouteSuccessPopup}
  onHide={() => setShowTracerouteSuccessPopup(false)}
  aria-labelledby="contained-modal-title-vcenter"
  centered
  className="inc-alert index-2000"
>
  <span className="success-icon">
    <i className="icon done"></i>
  </span>
  <p>Traceroute Test Success</p>
  <p>Test Results:<br/>
  traceroute to google.com (142.250.183.174), 30 hops max, 38 byte packets<br/>
   PR60X.lan (192.168.1.1)  0.000 ms  0.000 ms  0.000 ms <br/>
   172.26.40.1 (172.26.40.1)  0.000 ms  0.000 ms  0.000 ms <br/>
   103.170.91.33 (103.170.91.33)  0.000 ms  0.000 ms  0.000 ms <br/>
   *  *  <br/>
   103.27.168.9 (103.27.168.9)  0.000 ms  0.000 ms  0.000 ms <br/>
   192.178.81.9 (192.178.81.9)  0.000 ms  0.000 ms  0.000 ms <br/>
   142.250.63.52 (142.250.63.52)  0.000 ms  0.000 ms  0.000 ms <br/>
   142.250.234.126 (142.250.234.126)  20.000 ms  20.000 ms  20.000 ms <br/>
   172.253.70.160 (172.253.70.160)  20.000 ms  20.000 ms  20.000 ms<br/>
   216.239.48.64 (216.239.48.64)  20.000 ms  20.000 ms  20.000 ms<br/>
   142.251.77.69 (142.251.77.69)  30.000 ms  20.000 ms  20.000 ms<br/>
   142.251.64.13 (142.251.64.13)  20.000 ms  20.000 ms  20.000 ms<br/>
   google.com (142.250.183.174)  20.000 ms    20.000 ms<br/>
   Hop Count = 13 Last TTL = 13 Test attempt = 39 Test Success = 35
  </p>
  
  <Button onClick={() => setShowTracerouteSuccessPopup(false)}>OK</Button>
</Modal>

<Modal
  show={showDNSSuccessPopup}
  onHide={() => setShowDNSSuccessPopup(false)}
  aria-labelledby="contained-modal-title-vcenter"
  centered
  className="inc-alert index-2000"
>
  <span className="success-icon">
    <i className="icon done"></i>
  </span>
  <p>DNS Lookup Success</p>
  <p>
   Test Result:
  2404:6800:4009:802::200e', '142.250.67.206
  </p>
  <Button onClick={() => setShowDNSSuccessPopup(false)}>OK</Button>
</Modal>





      {showFailurePopup && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Failure</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClosePopups}
                ></button>
              </div>
              <div className="modal-body">
                <p>The ping test failed. Please try again later.</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={handleClosePopups}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Troubleshoot;
