import React from "react";

function cableTest() {
  return (
    <div>
      <div className="tab-form">
        <div className="row mb-4">
          <div className="col-lg-12">
            <strong>Conduct a test to determine if a cable is working and the fault distance.</strong> 
            <p className="mt-4">Select port to test:</p> 
              <div className="router-port left mt-3">
                <div className="mxw-1260">
                  <div className="polygon"></div>
                  <div class="port-box">
                    <ul class="port">
                      <li class="assign">1</li>
                      <li class="assign">2</li>
                      <li>3</li>
                      <li>4</li>
                      <li>5</li>
                      <li>6</li>
                      <li>7</li>
                      <li>8</li>
                      <li class="assign">9</li>
                      <li>10</li>
                      <li>11</li>
                      <li class="assign">12</li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="tab-form">
        <div className="inc-card-table">
          <table>
            <thead>
              <th>Port</th>
              <th>Test Result</th>
              <th>Fault Distance</th>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Cable Detected</td>
                <td>Working Fine</td>
              </tr>
              <tr>
                <td>2</td>
                <td>No Cable</td>
                <td>-</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Cable Detected</td>
                <td>2 meter</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Cable Detected</td>
                <td>Working Fine</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">
          Cancel
        </button>
        <button type="submit" className="text-btn primary-btn" data-bs-toggle="modal" data-bs-target="#cableTest">
          Test Selected Port
        </button>
        <button type="submit" className="text-btn primary-btn" data-bs-toggle="modal" data-bs-target="#cableTest">
          Start Another Test
        </button>
      </div>

      <div class="modal fade" id="cableTest" tabindex="-1" aria-labelledby="cableTestLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="cableTestLabel">Cable Test</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Cable test will disrupt connectivity to all devices on the selected port for a few seconds. 
                Note that if you are performing cable test on the port which connects the switch to the Internet, 
                you will lose Internet connectivity and Insight manager will show the device as offline while the test is performed.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline" data-bs-dismiss="modal">No, don't test</button>
              <button type="submit" class="text-btn primary-btn">Yes, Test the cable</button>
            </div>
          </div>
        </div>
      </div>

      

    </div>
  );
}

export default cableTest;