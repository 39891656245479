import React from 'react'
import { useEffect, useState } from "react";
import allApi from '../../../../api/allApi';
import Loader from '../../common/Loader';
import { useLocation } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { useNavigate } from "react-router-dom";

function WiredUsageExpansion() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [portData, setPortData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const api = new allApi();
        const response = await api.getRequest("api/wired/dashboard/portUsage");

        if (response) {
          console.log(response);
          setPortData({
            serialNumber: response.serialNumber,
            numOfPorts: response.numOfPorts,
            success: response.success,
            failure: response.failure,
          });
        }
      } catch (error) {
        console.error("Error fetching ports", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);



  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Usage</h3>
            <div className="inc-card-button">
              {/* <input className="list-search" type="text" /> */}
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              {/* <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button> */}
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                <div className="inc-card-body">
                  <div className="trends-legends position-relative">
                    <ul style={{ flex: "auto" }}>
                      <li>
                        <span className="d1"></span>Connected Using Power
                      </li>
                      <li>
                        <span className="d2"></span>Connected Not Using Power
                      </li>
                      <li>
                        <span className="d3"></span>Disabled
                      </li>
                      <li>
                        <span className="d4"></span>Error
                      </li>
                      <li>
                        <span className="d5"></span>Free
                      </li>
                    </ul>
                  </div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div>
                      {portData && portData.success.length > 0 ? (
                        portData.success.map((port, index) => (
                          <div key={index} className="wd-usage-chart">
                            <div className="wd-d-port">
                              <h4 style={{ textAlign: "left" }}>
                                {port.serialNumber}
                              </h4>
                              <h4 style={{ textAlign: "center" }}>
                                {port.numOfPorts} Port{port.numOfPorts > 1 ? 's' : ''}
                              </h4>
                            </div>
                            <ResponsiveContainer width="100%" height={40}>
                              <BarChart
                                layout="vertical"
                                data={[port]}
                                margin={{
                                  top: 0,
                                  right: 0,
                                  left: -50,
                                  bottom: 0,
                                }}
                              >
                                <CartesianGrid />
                                <XAxis
                                  type="number"
                                  domain={[0, port.numOfPorts]}
                                />
                                <YAxis type="category" />
                                <Tooltip />
                                <Bar
                                  dataKey="numOfActivePortsUsingPower"
                                  stackId="a"
                                  fill="#33A02C"
                                  name="Connected Using Power"
                                />
                                <Bar
                                  dataKey="numOfActivePortsNotUsingPower"
                                  stackId="a"
                                  fill="#1F78B4"
                                  name="Connected Not Using Power"
                                />
                                <Bar
                                  dataKey="numOfDisabledPorts"
                                  stackId="a"
                                  fill="#FB9A99"
                                  name="Disabled"
                                />
                                <Bar
                                  dataKey="numOfErrorPorts"
                                  stackId="a"
                                  fill="#B2DF8A"
                                  name="Error"
                                />
                                <Bar
                                  dataKey="numOfFreePorts"
                                  stackId="a"
                                  fill="#A6CEE3"
                                  name="Free"
                                />
                              </BarChart>
                            </ResponsiveContainer>
                            <hr />
                          </div>
                        ))
                      ) : (
                        <div className="no-data-box">
                          <div className="no-data-text">No Data Available</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Ports</th>
                        </tr>
                      </thead>
                      <tbody>
                        {portData && portData.success && portData.success.length > 0 ? (
                          portData.success.map((port, index) => (
                            <tr key={index}>
                              <td>{port.serialNumber}</td>
                              <td>{port.numOfPorts} </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default WiredUsageExpansion;
