import React, { useEffect, useState } from "react";
import lanPort from "../../../assets/images/lan-port.png";
import noSignal from "../../../assets/images/no-signal.svg";
import dotSignal from "../../../assets/images/dot-signal.svg";
import oneSignal from "../../../assets/images/one-signal.svg";
import twoSignal from "../../../assets/images/two-signal.svg";
import fullSignal from "../../../assets/images/full-signal.svg";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";

function Client() {
  const [wiredClients, setWiredClients] = useState([]);
  const [wirelessClients, setWirelessClients] = useState([]);
  const [disconnectedClients, setDisconnectedClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const connectedPromise = api.getRequest("api/client/connected");
      const disconnectedPromise = api.getRequest("api/client/disconnected");
      const [connectedResponse, disconnectedResponse] = await Promise.all([
        connectedPromise,
        disconnectedPromise,
      ]);
      const { wiredClients, wirelessClients } = connectedResponse;
      setWiredClients(wiredClients);
      setWirelessClients(wirelessClients);
      setDisconnectedClients(disconnectedResponse);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const getFilteredClients = () => {
    switch (filter) {
      case "wired":
        return wiredClients;
      case "wireless":
        return wirelessClients;
      case "all":
      default:
        return [...wiredClients, ...wirelessClients];
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link um active"
                  id="connected-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#connected-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="connected-tab-pane"
                  aria-selected="true"
                >
                  Connected
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rm"
                  id="disconnected-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#disconnected-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="disconnected-tab-pane"
                  aria-selected="false"
                >
                  Disconnected
                </button>
              </li>
            </ul>
            <div className="inc-card-button user-button">
              {/* <button className="icon-btn" title="Download">
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Search">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button> */}
              <button className="icon-btn" title="Refresh" onClick={fetchData}>
                {refreshing ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                )}
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="connected-tab-pane"
                role="tabpanel"
                aria-labelledby="connected-tab"
                tabIndex="0"
              >
                <div className="client-con-type">
                  <span>Connection Type:</span>
                  <label>
                    <input
                      type="radio"
                      name="connectionType"
                      checked={filter === "all"}
                      onChange={() => setFilter("all")}
                    />
                    ALL ({wiredClients.length + wirelessClients.length}){" "}
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="connectionType"
                      checked={filter === "wired"}
                      onChange={() => setFilter("wired")}
                    />
                    Wired ({wiredClients.length}){" "}
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="connectionType"
                      checked={filter === "wireless"}
                      onChange={() => setFilter("wireless")}
                    />
                    Wireless ({wirelessClients.length}){" "}
                  </label>
                </div>
                <div className="inc-card-table client-connection">
                  <table className="m-head">
                    <thead>
                      <tr>
                        <th>Client Name</th>
                        <th>Connection</th>
                        <th>Associated Device</th>
                        <th>Device Model</th>
                        <th>SSID</th>
                        <th>Port</th>
                        <th>OS</th>
                        <th>MAC Address</th>
                        <th>IP Address</th>
                        <th>RSSI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        getFilteredClients().map((client) => (
                          <tr key={client.deviceName}>
                            <td>{client.deviceName}</td>
                            <td>
                              <img src={lanPort} alt="Device" />
                            </td>
                            <td>{client.connDeviceName}</td>
                            <td>{client.connDeviceModel}</td>
                            <td>{client.ssid || "N/A"}</td>
                            <td>{client.port || "N/A"}</td>
                            <td>{client.platform || "N/A"}</td>
                            <td>{client.macAddress}</td>
                            <td>{client.ipAddress}</td>
                            <td className="rssi-icon">
                              {/* {client.rssi || "N/A"} */}
                              {client.rssi >= 80 ? (
                                <img src={fullSignal} />
                              ) : client.rssi >= 60 ? (
                                <img src={twoSignal} />
                              ) : client.rssi >= 40 ? (
                                <img src={oneSignal} />
                              ) : client.rssi >= 20 ? (
                                <img src={dotSignal} />
                              ) : (
                                <img src={noSignal} />
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="disconnected-tab-pane"
                role="tabpanel"
                aria-labelledby="disconnected-tab"
                tabIndex="0"
              >
                <div className="inc-card-table">
                  <table className="m-head">
                    <thead>
                      <tr>
                        <th>Client Name</th>
                        <th>Connected</th>
                        <th>Associated Device</th>
                        <th>Device Model</th>
                        <th>SSID</th>
                        <th>Port</th>
                        <th>OS</th>
                        <th>MAC Address</th>
                        <th>IP Address</th>
                        <th>RSSI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {disconnectedClients.map((client) => (
                        <tr key={client.clientId}>
                          <td>{client.deviceName}</td>
                          <td>
                            <img src={lanPort} alt="Device" />
                          </td>
                          <td>{client.connDeviceName}</td>
                          <td>{client.connDeviceModel}</td>
                          <td>{client.ssid}</td>
                          <td>{client.port}</td>
                          <td>{client.platform}</td>
                          <td>{client.macAddress}</td>
                          <td>{client.ipAddress}</td>
                          <td className="rssi-icon">
                            {client.rssi >= 80 ? (
                              <img src={fullSignal} />
                            ) : client.rssi >= 60 ? (
                              <img src={twoSignal} />
                            ) : client.rssi >= 40 ? (
                              <img src={oneSignal} />
                            ) : client.rssi >= 20 ? (
                              <img src={dotSignal} />
                            ) : (
                              <img src={noSignal} />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Client;
