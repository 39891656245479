import React, { useEffect, useState } from "react";
import allApi from "../../../api/allApi";
import { useLocation } from "react-router-dom";
import Loader from "../common/Loader";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from "recharts";
import moment from "moment";

function App() {
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  //const severityLevel = queryParams.get("severityLevel");
  const [severityLevel, setSeverityLevel] = useState(null);
  const [totalDevices, setTotalDevices] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deviceListings, setDeviceListings] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [devicesPerPage] = useState(10);
  const [isSearchOpen, setIsSearchOpen] = useState({
    device_name: false,
    alarmType: false,
    severityLevel: false,
    is_acknowledged: false,
    is_autoclear: false,
    notification: false,
    alarm_behaviour: false,
    alarm_description: false,
    alarm_time: false,
    acked_time: false,
    cleared_time: false,
    is_read: false,
    event_name: false,
    device_ipv6: false,
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [alarmToDelete, setAlarmToDelete] = useState(null);
  const [faults, setFaults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [lastPage, setLastPage] = useState();
  const [sortOrder, setSortOrder] = useState({
    device_name: "asc",
    ipv4_address: "asc",
    severityLevel: "asc",
    alarm_behaviour: "asc",
    event_name: "asc",
    alarm_time: "asc",
    alarm_description: "asc",
  });
  const [alertsData, setAlertsData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("1w");
  const [trendsLoading, settrendsLoading] = useState(true);
  const [trendsData, setTrendsData] = useState([]);
  useEffect(() => {
    if (location.state && location.state.severityAlerts) {
      const severityAlerts = location.state.severityAlerts;
      setDeviceListings(severityAlerts);
      setFilteredDevices(severityAlerts);
      setTotalDevices(severityAlerts.length);
      setLoading(false);
    } else {
      fetchAlarmData();
    }
  }, [location.state]);

  useEffect(() => {
    fetchAlertsChartData();
  }, []);

  useEffect(() => {
    fetchAlertTrends(selectedInterval);
  }, [selectedInterval]);

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };
  // useEffect(() => {
  //   if (severityLevel && !location.state) {
  //     filterDevicesBySeverity(severityLevel, deviceListings);
  //   }
  // }, [severityLevel, deviceListings]);

  const filterDevicesBySeverity = () => {
    if (severityLevel) {
      const filtered = deviceListings.filter(
        (alarm) => alarm.severityLevel === severityLevel
      );
      setFilteredDevices(filtered);
      setTotalDevices(filtered.length);
    } else {
      setFilteredDevices(deviceListings);
      setTotalDevices(deviceListings.length);
    }
  };

  const fetchAlarmData = async () => {
    setLoading(true);
    try {
      const api = new allApi();
      const alarmResponse = await api.getRequest("api/alarms/all");
      if (Array.isArray(alarmResponse)) {
        setDeviceListings(alarmResponse);
        setFilteredDevices(alarmResponse);
        setTotalDevices(alarmResponse.length);
      } else {
        console.error("Fetched data is not an array:", alarmResponse);
        setDeviceListings([]);
        setFilteredDevices([]);
        setTotalDevices(0);
      }
    } catch (error) {
      console.error("Error fetching alarm data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (alarmId) => {
    try {
      const api = new allApi();
      await api.deleteRequest(`api/alarms/${alarmId}`);
      fetchAlarmData();
      setAlarmToDelete(null);
    } catch (error) {
      console.error("Error deleting alarm:", error);
    }
  };

  const handleSelectAll = (isChecked) => {
    setSelectAllChecked(isChecked);
    if (isChecked) {
      setSelectedCheckboxes(filteredDevices.map((device) => device.alarm_id));
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleSearch = (e) => {
    if (e.key === "Backspace") {
      e.target.value = "";
      fetchAlarmData();
    } else {
      setFilteredDevices(
        currentDevices.filter((list) =>
          list[e.target.name]
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const handleSearchBox = (header) => {
    const updatedSearchOpen = {
      ...isSearchOpen,
      [header]: !isSearchOpen[header],
    };
    setIsSearchOpen(updatedSearchOpen);
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const totalFilteredDevices = filteredDevices.length;
  const currentDevices = filteredDevices.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );

  const handleFilterDevice = () => {
    return filteredDevices.length
      ? filteredDevices.slice(
          (currentPage - 1) * devicesPerPage,
          currentPage * devicesPerPage
        )
      : [];
  };

  const handleSortChange = (field) => {
    // Toggle the sort order for the clicked field
    const newOrder = sortOrder[field] === "asc" ? "desc" : "asc";

    // Sort the gateways based on the field and order
    const sortedDevices = [...filteredDevices].sort((a, b) => {
      // Handle undefined values gracefully
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";

      if (newOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    setFilteredDevices(sortedDevices);
    setSortOrder((prevState) => ({
      ...prevState,
      [field]: newOrder, // Set the new sort order for the field
    }));
  };

  const successhandleClose = () => {
    setShow(false);
    setAlarmToDelete(null);
  };

  const totalPages = Math.ceil(totalFilteredDevices / devicesPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  const COLORS = [
    "#FF6B6B",
    "#FFAD01",
    "#26BF78",
    "#118AB2",
    "#a4de6c",
    "#8dd1e1",
  ];

  const fetchAlertsChartData = async () => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest("api/alarms/charts");

      if (response?.response) {
        // Transform the data into an array suitable for the chart
        const formattedData = Object.keys(response.response).map(
          (category) => ({
            name: category,
            value: response.response[category].count,
          })
        );
        setAlertsData(formattedData);
      } else {
        console.error("Unexpected API response:", response);
        setAlertsData([]);
      }
    } catch (error) {
      console.error("Error fetching alerts data:", error);
    } finally {
      setLoading(false);
    }
  };
  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        className="custom-legend category"
        style={{
          listStyleType: "none",
          padding: 0,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 10,
              marginBottom: 6,
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: entry.color,
                marginRight: 6,
              }}
            />
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const fetchAlertTrends = async () => {
    settrendsLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/alarms/alertTrends?duration=${selectedInterval}&category=Threshold&severityLevel=3`
      );

      if (Array.isArray(response)) {
        const trendsData = response.map((item) => {
          const formattedTime = formatAlertTime(item.alert_time);
          return {
            ...item,
            alert_time: formattedTime, // Call formatAlertTime based on format
          };
        });
        setTrendsData(trendsData);
      } else {
        setTrendsData([]);
      }
    } catch (error) {
      console.error("Error fetching alerts data:", error);
    } finally {
      settrendsLoading(false);
    }
  };

  const formatAlertTime = (alertTime) => {
    if (/^\d{2}:\d{2}$/.test(alertTime)) {
      const today = new Date();
      const [hours, minutes] = alertTime.split(":");
      today.setHours(hours);
      today.setMinutes(minutes);
      today.setSeconds(0);
      today.setMilliseconds(0);

      return today;
    }

    // Check if the time is in "DD-MM-YYYY" format (for other filters)
    if (/^\d{2}-\d{2}-\d{4}$/.test(alertTime)) {
      const [day, month, year] = alertTime.split("-");
      const formattedDate = new Date(`${year}-${month}-${day}T00:00:00Z`); // ISO format
      return isNaN(formattedDate.getTime()) ? null : formattedDate;
    }
    // If the format doesn't match, return null
    console.error("Invalid alert_time format:", alertTime);
    return null;
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row mb-4">
          <div className="col-lg-5">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Category</h3>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    <div className="col-lg-6">
                      <ResponsiveContainer width="100%" height={250}>
                        <PieChart>
                          <Pie
                            data={alertsData}
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={90}
                            fill="#8884d8"
                            paddingAngle={1}
                            dataKey="value"
                          >
                            {alertsData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          {/* <Legend
                            content={renderLegend}
                            verticalAlign="bottom"
                            height={60}
                            wrapperStyle={{ marginTop: "20px" }}
                          />  */}
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="col-lg-6 category-label">
                      <ul>
                        <li className="ms">
                          <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                          Miscellaneous
                        </li>
                        <li className="dc">
                          <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                          Device Configuration
                        </li>
                        <li className="ds">
                          <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                          Device Status
                        </li>
                        <li className="th">
                          <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                          Threshold
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Alerts Summary</h3>
                <span
                  className="dropdown"
                  id="trendDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {selectedInterval}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="trendDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleIntervalChange("24h");
                        }}
                      >
                        24 Hour
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleIntervalChange("1w");
                        }}
                      >
                        1 Week
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleIntervalChange("2w");
                        }}
                      >
                        2 Week
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleIntervalChange("1m");
                        }}
                      >
                        Monthly
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="inc-card-body">
                {trendsLoading ? (
                  <Loader />
                ) : (
                  <ResponsiveContainer width="100%" height={250}>
                    <LineChart
                      data={trendsData}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="alert_time"
                        tickFormatter={(value) => {
                          const date = new Date(value);
                          return selectedInterval === "24h"
                            ? date.toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                            : date.toLocaleDateString();
                        }}
                      />

                      <YAxis dataKey="alert_count" />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="alert_count"
                        stroke="#8884d8"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inc-card h-auto">
        <div className="inc-card-title">
          <h3>Alerts List</h3>
          <div className="inc-card-button">
            <button
              className="icon-btn"
              title="Refresh"
              onClick={() => fetchAlarmData()}
            >
              {refreshing ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-refresh" aria-hidden="true"></i>
              )}
            </button>

            {/* <button className="icon-btn" title="Download">
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button> */}
          </div>
        </div>
        <div className="inc-card-body">
          <div>
            <div className="inc-card-table">
              <table className="full-table m-head">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      Device Name
                      <i
                        className={`fa ${
                          sortOrder.device_name === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                        }`}
                        onClick={() => handleSortChange("device_name")}
                        aria-hidden="true"
                      ></i>
                    </th>
                    <th>
                      Device IP
                      <i
                        className={`fa ${
                          sortOrder.ipv4_address === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                        }`}
                        onClick={() => handleSortChange("ipv4_address")}
                        aria-hidden="true"
                      ></i>
                    </th>
                    <th>
                      Severity Level
                      <i
                        className={`fa ${
                          sortOrder.severityLevel === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                        }`}
                        onClick={() => handleSortChange("severityLevel")}
                        aria-hidden="true"
                      ></i>
                    </th>
                    <th>
                      Alerts Behaviour
                      <i
                        className={`fa ${
                          sortOrder.alarm_behaviour === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                        }`}
                        onClick={() => handleSortChange("alarm_behaviour")}
                        aria-hidden="true"
                      ></i>
                    </th>
                    <th>
                      Event Name
                      <i
                        className={`fa ${
                          sortOrder.event_name === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                        }`}
                        onClick={() => handleSortChange("event_name")}
                        aria-hidden="true"
                      ></i>
                    </th>
                    <th>
                      Alerts Time
                      <i
                        className={`fa ${
                          sortOrder.alarm_time === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                        }`}
                        onClick={() => handleSortChange("alarm_time")}
                        aria-hidden="true"
                      ></i>
                    </th>
                    <th>
                      Alerts Description
                      <i
                        className={`fa ${
                          sortOrder.alarm_description === "asc"
                            ? "fa fa-sort-amount-desc"
                            : "fa fa-sort-amount-asc"
                        }`}
                        onClick={() => handleSortChange("alarm_description")}
                        aria-hidden="true"
                      ></i>
                    </th>
                  </tr>
                  <tr className="search-border">
                    <td></td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="device_name"
                        onKeyUp={(e) => handleSearch(e)}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="ipv4_address"
                        onKeyUp={(e) => handleSearch(e)}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="severityLevel"
                        onKeyUp={(e) => handleSearch(e)}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="alarm_behaviour"
                        onKeyUp={(e) => handleSearch(e)}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="event_name"
                        onKeyUp={(e) => handleSearch(e)}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="alarm_time"
                        onKeyUp={(e) => handleSearch(e)}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="alarm_description"
                        onKeyUp={(e) => handleSearch(e)}
                      />
                    </td>
                  </tr>
                </thead>
                {loading ? (
                  <tr>
                    <td colSpan="8">
                      <Loader />
                    </td>
                  </tr>
                ) : filteredDevices === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan="8">
                        <div style={{ textAlign: "center" }}>
                          <p>No alerts to display</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {currentDevices.map((device, index) => (
                      <tr key={index}>
                        <td></td>
                        <td>{device.device_name || "N/A"}</td>
                        <td>{device.device_ip || "N/A"}</td>
                        <td>{device.severityLevel || "N/A"}</td>
                        <td>{device.alarm_behaviour || "N/A"}</td>
                        <td>{device.event_name || "N/A"}</td>
                        <td>{device.alarm_time || "N/A"}</td>
                        <td>{device.alarm_description || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="pagination-container">
          <div className="pagination-info">
            <p>
              Showing {indexOfFirstDevice + 1}
              {"-"}
              {Math.min(
                currentPage * devicesPerPage,
                totalFilteredDevices
              )} of {totalFilteredDevices} records
            </p>
          </div>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </button>
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                className={currentPage === page ? "active" : ""}
              >
                {page}
              </button>
            ))}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              Last Page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
